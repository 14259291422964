var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-list mt-4 ml-8 mr-8"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"display-2 mb-5"},[_vm._v(" Benutzer ")])]),_c('v-col',{staticClass:"text-sm-right"},[_c('tooltip-button',{attrs:{"icon":"person_add","text":"Benutzer erfassen","color":"primary","classNames":"ml-3","position":"top"},on:{"click":function($event){return _vm.openCreateDialog()}}})],1)],1),_c('v-card',{staticClass:"elevation-0",attrs:{"color":"default","outlined":""}},[_c('v-card-text',[(_vm.renderDatatable)?_c('remote-data-table',{ref:"usersDatatable",attrs:{"url":'backoffice/users',"search-enabled":true,"headers":_vm.headers,"filters":_vm.filters,"sort-by":"-created_at","allow-all":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [(_vm.canBeModified(item.group))?_c('router-link',{attrs:{"to":("/users/" + (item.id))}},[_vm._v(" "+_vm._s(item.id)+" ")]):_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [(_vm.canBeModified(item.group))?_c('router-link',{attrs:{"to":("/users/" + (item.id))}},[_vm._v(" "+_vm._s(item.first_name)+" ")]):_c('span',[_vm._v(_vm._s(item.first_name))])]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [(_vm.canBeModified(item.group))?_c('router-link',{attrs:{"to":("/users/" + (item.id))}},[_vm._v(" "+_vm._s(item.last_name)+" ")]):_c('span',[_vm._v(_vm._s(item.last_name))])]}},{key:"item.tenant_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/tenants/" + (item.tenant.id))}},[_vm._v(" "+_vm._s(item.tenant.name)+" ")])]}},{key:"item.siport_profiles",fn:function(ref){
var item = ref.item;
return [_c('siport-line',{attrs:{"profiles":item.siport_profiles}})]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [(item.primary_address)?_c('address-line',{attrs:{"address":item.primary_address}}):(item.billing_address)?_c('address-line',{attrs:{"address":item.billing_address}}):_c('address-line',{attrs:{"address":item.tenant_address}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"80px"}},[(item.is_member_inhouse)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" house ")])]}}],null,true)},[_c('span',[_vm._v("Mieter")])]):_vm._e(),(item.is_locked)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","dark":""}},'v-icon',attrs,false),on),[_vm._v(" lock ")])]}}],null,true)},[_c('span',[_vm._v("Wurde gesperrt")])]):_vm._e(),(item.avatar)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" portrait ")])]}}],null,true)},[_c('span',[_vm._v("Hat Profilbild")])]):_vm._e()],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('scope-group-label',{attrs:{"user":item,"small":""}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('tooltip-button',{attrs:{"disabled":!_vm.canBeModified(item.group),"icon":"delete","text":"Löschen","color":"default","dark":false,"classNames":"elevation-0 ml-1 mt-1 mb-1","position":"top","size":"x-small"},on:{"click":function($event){return _vm.openDeleteDialog(
                  item.id,
                  item.first_name + ' ' + item.last_name
                )}}})]}}],null,false,1790355511)}):_vm._e()],1)],1)],1),_c('user-dialog',{ref:"userDialog",on:{"saved":_vm.goToUser}}),_c('delete-dialog',{ref:"deleteDialog",on:{"deleted":function($event){return _vm.$refs.usersDatatable.getDataFromApi()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }