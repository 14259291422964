<template>
  <div class="scope-group-label">
    <v-chip :color="color" dark label :small="small" :large="large">
      {{ user.group.name }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      group: Object,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    color() {
      if (this.user.group.id === "admin") {
        return "purple";
      }
      if (this.user.group.id === "hdw") {
        return "purple darken-4";
      }
      if (this.user.group.id.indexOf("backoffice") >= 0) {
        return "pink";
      }
      return "blue";
    }
  }
};
</script>

<style></style>
