<template>
  <div class="users-list mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">
            Benutzer
          </h1>
        </v-col>
        <v-col class="text-sm-right">
          <tooltip-button
            icon="person_add"
            text="Benutzer erfassen"
            color="primary"
            @click="openCreateDialog()"
            classNames="ml-3"
            position="top"
          />
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text>
          <remote-data-table
            v-if="renderDatatable"
            :url="'backoffice/users'"
            :search-enabled="true"
            :headers="headers"
            :filters="filters"
            sort-by="-created_at"
            ref="usersDatatable"
            allow-all
          >
            <template v-slot:item.id="{ item }">
              <router-link
                v-if="canBeModified(item.group)"
                :to="`/users/${item.id}`"
              >
                {{ item.id }}
              </router-link>
              <span v-else>{{ item.id }}</span>
            </template>
            <template v-slot:item.first_name="{ item }">
              <router-link
                v-if="canBeModified(item.group)"
                :to="`/users/${item.id}`"
              >
                {{ item.first_name }}
              </router-link>
              <span v-else>{{ item.first_name }}</span>
            </template>
            <template v-slot:item.last_name="{ item }">
              <router-link
                v-if="canBeModified(item.group)"
                :to="`/users/${item.id}`"
              >
                {{ item.last_name }}
              </router-link>
              <span v-else>{{ item.last_name }}</span>
            </template>
            <template v-slot:item.tenant_id="{ item }">
              <router-link :to="`/tenants/${item.tenant.id}`">
                {{ item.tenant.name }}
              </router-link>
            </template>
            <template v-slot:item.siport_profiles="{ item }">
              <siport-line :profiles="item.siport_profiles" />
            </template>
            <template v-slot:item.address="{ item }">
              <address-line
                v-if="item.primary_address"
                :address="item.primary_address"
              />
              <address-line
                v-else-if="item.billing_address"
                :address="item.billing_address"
              />
              <address-line v-else :address="item.tenant_address" />
            </template>
            <template v-slot:item.status="{ item }">
              <div style="min-width: 80px;">
                <v-tooltip top v-if="item.is_member_inhouse">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      house
                    </v-icon>
                  </template>
                  <span>Mieter</span>
                </v-tooltip>
                <v-tooltip top v-if="item.is_locked">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="error" dark v-bind="attrs" v-on="on">
                      lock
                    </v-icon>
                  </template>
                  <span>Wurde gesperrt</span>
                </v-tooltip>
                <v-tooltip top v-if="item.avatar">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      portrait
                    </v-icon>
                  </template>
                  <span>Hat Profilbild</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:item.type="{ item }">
              <scope-group-label :user="item" small />
            </template>
            <template v-slot:item.action="{ item }">
              <tooltip-button
                :disabled="!canBeModified(item.group)"
                icon="delete"
                text="Löschen"
                color="default"
                :dark="false"
                @click="
                  openDeleteDialog(
                    item.id,
                    item.first_name + ' ' + item.last_name
                  )
                "
                classNames="elevation-0 ml-1 mt-1 mb-1"
                position="top"
                size="x-small"
              />
            </template>
          </remote-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <user-dialog ref="userDialog" @saved="goToUser" />
    <delete-dialog
      ref="deleteDialog"
      @deleted="$refs.usersDatatable.getDataFromApi()"
    />
  </div>
</template>

<script>
import DeleteDialog from "@/components/dialogs/DeleteDialog";
import RemoteDataTable from "@/components/RemoteDataTable";
import TooltipButton from "@/components/forms/TooltipButton";
import UserDialog from "@/components/dialogs/UserDialog";
import AddressLine from "@/components/forms/AddressLine.vue";
import ScopeGroupLabel from "@/components/labels/ScopeGroupLabel.vue";
import SiportLine from "../../components/forms/SiportLine";

export default {
  name: "tenants-list",

  components: {
    SiportLine,
    DeleteDialog,
    TooltipButton,
    RemoteDataTable,
    UserDialog,
    AddressLine,
    ScopeGroupLabel
  },

  data: () => ({
    renderDatatable: false,
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      { text: "Vorname", align: "left", sortable: true, value: "first_name" },
      { text: "Nachname", align: "left", sortable: true, value: "last_name" },
      { text: "E-Mail", align: "left", sortable: true, value: "email" },
      { text: "Mandant", align: "left", sortable: true, value: "tenant_id" },
      {
        text: "Badgenummer",
        align: "left",
        sortable: true,
        value: "badge_number"
      },
      {
        text: "SIPORT",
        align: "left",
        sortable: false,
        value: "siport_profiles"
      },
      {
        text: "Adresse",
        align: "left",
        sortable: false,
        value: "address"
      },
      {
        text: "Status",
        align: "center",
        sortable: false,
        value: "status"
      },
      {
        text: "Typ",
        align: "center",
        sortable: false,
        value: "type"
      },
      {
        text: "Aktionen",
        align: "right",
        sortable: false,
        value: "action"
      }
    ],
    search: null,
    filters: [
      {
        type: "select",
        field: "tenant_id",
        label: "Mandant",
        values: [],
        default: null
      },
      {
        type: "checkbox",
        field: "is_member_inhouse",
        label: "Nur Mieter",
        default: false
      }
    ]
  }),

  created() {
    this.fetchTenants();
  },

  methods: {
    /**
     * Fetch tenants from server
     */
    fetchTenants() {
      this.$api.http
        .get(`backoffice/tenants?_per_page=99999`)
        .then(response => {
          this.filters[0].values = response.data.data.map(tenant => {
            return {
              text: `${tenant.name}`,
              value: tenant.id
            };
          });
          this.filters[0].default = parseInt(this.$route.query._tenant);
          this.renderDatatable = true;
        });
    },

    /**
     * Open create dialog
     */
    openCreateDialog() {
      this.$refs.userDialog.open = true;
    },

    /**
     * Go to user edit view.
     */
    goToUser(userId) {
      this.$router.push(`/users/${userId}`);
    },

    /**
     * Open delete dialog
     */
    openDeleteDialog(id, email) {
      this.$refs.deleteDialog.entityId = id;
      this.$refs.deleteDialog.entityName = `#${id} ${email}`;
      this.$refs.deleteDialog.entityUrl = "users";
      this.$refs.deleteDialog.open = true;
    },

    /**
     * Check if user can be modified.
     */
    canBeModified(group) {
      return ["hdw", "admin"].indexOf(group) < 0;
    }
  }
};
</script>

<style lang="scss">
.users-list {
  a {
    text-decoration: none;
    color: var(--v-primary-darken1);
  }
}
</style>
