<template>
  <div class="siport-line">
    <template v-if="profiles">
      {{ profiles.join(", ") }}
    </template>
  </div>
</template>

<script>
export default {
  name: "SiportLine",
  props: ["profiles"]
};
</script>

<style scoped>
.siport-line {
  font-size: 12px;
}
</style>
